'use client';
import { useEffect } from 'react';
import { setUser } from '@sentry/nextjs';
import { useSession } from 'next-auth/react';

export function SentryUserManager() {
  const { data, status } = useSession();

  useEffect(() => {
    if (status === 'authenticated') {
      setUser({ id: data.user.userId });
    } else {
      setUser(null);
    }
  }, [status]);
  return null;
}
